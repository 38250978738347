import React from 'react'

import { IdentificationFailed } from '../components/modals'

class IdentificationFailedPage extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <IdentificationFailed />
      </div>
    )
  }
}

export default IdentificationFailedPage
